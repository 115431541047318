let emailCode

if(process.env.NODE_ENV==="production"){
  emailCode="NqyVidZHjDV07XeNREP6jfJ97wAgDafaSZ6kDhCx9JhYAzFudNp1Lw=="
}else{
  emailCode="krRCwwiP734LYpMXtuAbeybecLo9dPiVuPIBBnJHumazAzFuzu9Whg=="
}
export const urls = {
    user: {
      createUser: "/User",
      loginUser:"/Auth/login",
      getUserData:(payerID:any)=>`/User/${payerID}`,
      ForgotPassword:"/User/forgot-password",
      resetPassword:"/User/reset-password",
    },
    registerApplication:{
      registerApp:"/RegisteredApps",
      updateApplication:(appID:any)=>`/RegisteredApps/${appID}`,
      getClientconfigurations:"/api/ClientConfigurations",
      getTemplateById: (id: string) => `/template/get/${id}`,
      getRegisteredApps: (userId: number, pageNumber: number, pageSize: number) => `/RegisteredApps?ConnectUserID=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=createdAt&sortOrder=desc`,
      sendEmail:`/SendEmail?code=${emailCode}`,
      sendOtp:``,
      uploadLogo:"/Upload",
      getApplicationsData:(appID:any)=>`/RegisteredApps/${appID}`
    }
  };
  
  